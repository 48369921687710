
/* Modal.css */

.modal-overlay {
    
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
  }
 
  .modal-content {
    /* width: 50%; */
    /* height: auto; */
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: #e72828;
  }

  ::-webkit-scrollbar {
   width: 0px;
   background: transparent; /* make scrollbar transparent */
}

@media screen and (max-width: 2450px) {
  .modal-content {
     width: 35%;
  }
  .banner img{
    width: 100%;
  }
}

  @media screen and (max-width: 1550px) {
    .modal-content {
       width: 35%;
    }
    .banner img{
      width: 100%;
    }
 }

  @media screen and (max-width: 1250px) {
    .modal-content {
       width: 45%;
    }
    .banner img{
      width: 100%;
    }
 }

  @media screen and (max-width: 950px) {
    .modal-content {
       width: 50%;
    }
    .banner img{
      width: 100%;
    }
 }

  @media screen and (max-width: 650px) {
    .modal-content {
       width: 60%;
    }
    .banner img{
      width: 100%;
    }
 }

 @media screen and (max-width: 450px) {
    .modal-content {
       width: 80%;
    }
    .banner img{
      width: 100%;
    }
 }

  @media screen and (max-width: 350px) {
    .modal-content {
       width: 80%;
    }
    .banner img{
      width: 100%;
    }
 }

 @media screen and (max-width: 300px) {
    .modal-content {
       width: 90%;
    }
    .banner img{
      width: 100%;
    }
 }
  