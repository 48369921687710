.cardContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cards{
    flex-basis: 32%;
}

@media only screen and (max-width:768px) {
    .cardContainer{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-between;
    align-items: center;
}
    .cards{
    flex-basis: 100%;
}
}